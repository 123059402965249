
let form = document.getElementsByTagName("form");
let fileFromCount = 0;
let length = 0;
if(form) {
    for(let single of form){
        single.addEventListener("submit", function (e) {
            e.preventDefault();
            let files = single.querySelectorAll('[type="file"]');
            //let url = "/templates/static/php/upload.php";
            let url = "/ajax/upload.php";
            if(files.length > 0){
                fileFromCount = 0;
                length = files.length;
                files.forEach((fileElem, index)=>{
                    let data = new FormData();
                    let files = Array.from(fileElem.files);
                    files.forEach((file) => {
                        data.append(fileElem.id+'[]', file);
                    })
                    let xhr = new XMLHttpRequest();
                    xhr.onreadystatechange = function () {
                        if (this.readyState === XMLHttpRequest.DONE && this.status === 200) {
                            let response = JSON.parse(this.response).data;
                            if(typeof response.forEach == "function"){
                                fileFromCount++;
                                let link = single.querySelector('[name="'+ fileElem.getAttribute('data-link') +'"]');
                                let dat = {};
                                response.forEach((res, index) => {
                                    dat[index] = (res);
                                });

                                link.value = JSON.stringify(dat);
                                fileUploadSubmit(single);
                                //submitForm(single);
                            }
                        }
                    };
                    xhr.open("POST", url);
                    xhr.send(data);
                });
            }else{
                submitForm(single);
            }
        });
    }
}

function fileUploadSubmit(form){
    console.log(length, fileFromCount);
    if(fileFromCount === length){
        submitForm(form);
    }
}

function submitForm(single){
    let url = window.location.protocol + "//" + window.location.hostname + "/form/store";

    if(single.hasAttribute('login')){
        url = window.location.protocol + "//" + window.location.hostname + "/login/send";
    }
    if(single.hasAttribute('logout')){
        url = window.location.protocol + "//" + window.location.hostname + "/logout/send";
    }

    let data = new FormData(single);

    single.querySelector("[type='submit']").setAttribute('disabled', 'disabled');

    let xhr = new XMLHttpRequest();
    xhr.onreadystatechange = function () {

        if (this.readyState === XMLHttpRequest.DONE && this.status === 200) {

            let response = JSON.parse(this.responseText);
            if(response.login){
               
            }
            if(response.login || response.logout){
                window.location.hash="";
                if(window.parent){
                    response.changePage = true;
                    window.parent.postMessage(response, "*");
                    window.dispatchEvent(new CustomEvent("login", { detail: response }));
                }
                window.location.reload();
            }
            if(response.result === "fail"){
                wrongCredentials(single);
            }

            if(response.error){
                wrongCredentials(single);
            }

            if (response.success) {



             window.dataLayer.push({
                 'event': data.get("formtype")
             });


                if(single.hasAttribute('data-s')){
                    document.body.appendChild(createThank(single.getAttribute('data-thank')));
                
                }else if(single.hasAttribute('newsletter')){
                    document.body.appendChild(createThankNewsletter());
                
                }else{
                    document.body.appendChild(createThank());
                }
                single.reset();
            }
        }

    };
    xhr.open("POST", url);
    xhr.send(data);
}

function wrongCredentials(single){
    single.classList.add('wrong');
    single.reset();
    setTimeout(function() {
        single.classList.remove('wrong');
        single.querySelector("[type='submit']").removeAttribute('disabled');
    }, 1000);
}


function createThankNewsletter(message = "Děkujeme za odeslání žádosti. <p>Na vámi uvedenou emailovou adresu jsme odeslali zprávu s odkazem pro dokončení přihlášení k odběru Newsletteru. Postupujte prosím podle instrukcí v emailové zprávě.</p>"){
    let popup = document.createElement("div");
    popup.classList.add("form-success");
    let wrapper = document.createElement("div");
    wrapper.classList.add("form-success--wrapper");
    let text = document.createElement("h3");
    text.innerHTML = message;
    wrapper.appendChild(text);
    popup.appendChild(wrapper);
    setTimeout(function(){
        popup.remove();
        // location.reload();
    },2000);
    return popup;
}

function createThank(message = "Děkujeme za odeslání formuláře!"){
    let popup = document.createElement("div");
    popup.classList.add("form-success");
    let wrapper = document.createElement("div");
    wrapper.classList.add("form-success--wrapper");
    let text = document.createElement("h3");
    text.innerHTML = message;
    wrapper.appendChild(text);
    popup.appendChild(wrapper);
    setTimeout(function(){
        popup.remove();
        location.reload();
    },2000);
    return popup;
}


let fileInput =  document.querySelector("form input#file");
let fileInputLabel =  document.querySelector('form label[for="file"] span');
if(fileInput && fileInputLabel){
    fileInput.addEventListener('input', function(e){
        fileInputLabel.innerHTML = e.target.files[0].name;
    });
}
